<template>
  <div>
    <div class="adsdetail-cnt" v-if="adsDetail">
      <header class="inner-header">
        <div class="top-head">
          <img src="../assets/images/backIcon.svg" alt="" @click="$router.go(-1)">
          <span
            class="edit-btn"
            @click="
              $router.push({
                path: '/create-ad-form',
                query: { AdId: adsDetail.campaignIdentifier },
              })
            "
            >{{ $t('Edit detail') }}</span
          >
        </div>

        <h2>
          {{ adsDetail.name }}
        </h2>
      </header>

      <section class="mid-cnt">
        <div class="ads-status">
          <!-- <div class="status-col">
            <span class="text-val">12</span>
            <p>Orders</p>
          </div> -->
          <div class="status-col">
            <div class="status-box">
              <span class="text-val">{{ adsDetail.clickCount }}</span>
              <p>{{ $t('Clicks') }}</p>
            </div>
          </div>

          <div class="status-col">
            <div class="status-box">
              <span class="text-val">{{ adsDetail.viewCount }}</span>
              <p>{{ $t('Viewers') }}</p>
            </div>
          </div>
        </div>

        <!-- <div class="order-dtl">
          <h3>Order ID: <span>#ads78654</span></h3>
          <h4>{{ adsDetail.name }}</h4>
          <p>
            {{
              adsDetail.campaignAction.callNumber ||
                adsDetail.campaignAction.whatsAppNumber
            }}
          </p>
          <p>15 Jul 20, 15:32 PM</p>
          <p>I.T. Tower, Plot No 29, Sector 142, Noida, Uttar Pradesh 201301</p>
          <p>Amount Paid:<span>₹120</span></p>
          <p>Ad Fees Deduction:<span>₹10</span></p>
        </div> -->

        <div class="detail-box">
          <div :class="isDisable ? 'adbanner-staus disable-btn' : 'adbanner-staus'" v-if="(adsDetail.status === 2)">
            <span
              class="status-btn play-ad"
              @click="handlePlayPauseAd"
              :class="adBannerStatus ? 'play-ad' : 'pause-ad'"
            >
              <span v-if="adBannerStatus">{{ $t('AdPlay') }}</span>
              <span v-else>{{ $t('AdPause') }}</span>
            </span>
          </div>

          <div class="info-list">
            <h4>{{ $t('AdName') }}</h4>
            <p>{{ adsDetail.name }}</p>
          </div>

          <div class="info-list">
            <h4>{{ $t('AdDescription') }}</h4>
            <p>
              {{ adsDetail.description }}
            </p>
          </div>

          <div
            class="info-list"
            v-if="
              adsDetail.campaignAction.callNumber ||
                adsDetail.campaignAction.whatsAppNumber
            "
          >
            <h4>{{ $t('Phone') }}:</h4>
            <p>
              {{
                adsDetail.campaignAction.callNumber ||
                  adsDetail.campaignAction.whatsAppNumber
              }}
            </p>
          </div>
          <div class="info-list">
            <h4>{{ $t('Status') }}</h4>
            <p>
              {{
                new Date().getTime() > adsDetail.endDate ? "Expired" :
                adsDetail.status === 1
                  ? 'Pending'
                  : adsDetail.status === 2
                  ? 'Approved'
                  : 'Rejected'
              }}
            </p>
          </div>

          <!-- <div
            class="info-list"
            v-if="adsDetail.campaignAction.hasOwnProperty('actionURL') && (adsDetail.mediaType === 1)"
          >
            <h4>{{ $t('Website') }}</h4>
            <p>
              {{ adsDetail.campaignAction.actionURL }}
            </p>
          </div> -->

          <div class="info-list">
            <h4>{{ $t('Created At') }}</h4>
            <p>
              {{ extractDate(adsDetail.startDate) }}
            </p>
          </div>

          <div class="info-list">
            <h4>{{ $t('Expired on') }}</h4>
            <p>
              {{ extractDate(adsDetail.endDate) }}
            </p>
          </div>

          <div v-if="adsDetail.hasOwnProperty('leadData')">
            <div
              class="info-list"
              v-if="adsDetail.leadData.length && adsDetail.leadData[0].email"
            >
              <h4>{{ $t('Customer Email') }}</h4>
              <p>
                {{ adsDetail.leadData && adsDetail.leadData[0].email }}
              </p>
            </div>

            <div
              class="info-list"
              v-if="adsDetail.leadData.length && adsDetail.leadData[0].name"
            >
              <h4>{{ $t('Customer Name') }}</h4>
              <p>
                {{ adsDetail.leadData && adsDetail.leadData[0].name }}
              </p>
            </div>

            <div
              class="info-list"
              v-if="
                adsDetail.leadData.length && adsDetail.leadData[0].phoneNumber
              "
            >
              <h4>{{ $t('Customer Phone') }}</h4>
              <p>
                {{ adsDetail.leadData && adsDetail.leadData[0].phoneNumber }}
              </p>
            </div>

            <div
              class="info-list"
              v-if="adsDetail.leadData.length && adsDetail.leadData[0].pincode"
            >
              <h4>{{ $t('Customer Pincode') }}</h4>
              <p>
                {{ adsDetail.leadData && adsDetail.leadData[0].pincode }}
              </p>
            </div>

            <div
              class="info-list"
              v-if="adsDetail.leadData.length && adsDetail.leadData[0].pincode"
            >
              <h4>{{ $t('Customer Pincode') }}</h4>
              <p>
                {{ adsDetail.leadData && adsDetail.leadData[0].pincode }}
              </p>
            </div>
          </div>

<!--          <div class="info-list" v-if="adsDetail.pincodes.length">-->
<!--            <h4>{{ $t('Ads available on pinodes') }}</h4>-->
<!--            <p>-->
<!--              {{ adsDetail.pincodes.join(', ') }}-->
<!--            </p>-->
<!--          </div>-->

          <div class="info-list" v-if="adsDetail.campaignMedia.length || adsDetail.leadMedia">
            <h4>{{ $t('AdImage') }}</h4>
            <div class="upload-item">
              <img
                v-if="adsDetail.campaignMedia.length"
                :src="
                  adsDetail.campaignMedia && adsDetail.campaignMedia[0].mediaURL
                "
              />
              <img
                v-if="adsDetail.leadMedia"
                :src="adsDetail.leadMedia.mediaURL"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </div>
    <loadingIcon v-if="loading" />
    <Toaster :dataValue="toasterData" />
  </div>
</template>
<script>
import Toaster from '../components/Toaster';
import loadingIcon from '../components/icons/loadingIcon';
import { SessionHelper } from '../services/Session';
import ApiRequest from '../services/ApiRequest';
import { BASE_API_URL } from '../utils/constants';

export default {
  name: 'AdDetails',
  components: {
    Toaster,
    loadingIcon,
  },
  data() {
    return {
      adsDetail: null,
      toasterData: {},
      loading: true,
      adBannerStatus: false,
      isDisable: false
    };
  },
  watch:{
    adsDetail: function(newVal){
      if(newVal.isRunning){
        this.adBannerStatus = false
      }else{
        this.adBannerStatus = true
      }
    }
  },
  methods: {
    handlePlayPauseAd(){
      this.isDisable = true
      let payload = {
        campaignIdentifier : this.$route.query.AdNo,
        isRunning: this.adBannerStatus
      }
      ApiRequest(BASE_API_URL,`/campaign/update-ads-running-status`, "put",payload).then((data)=>{
        this.isDisable = false
        if(data.httpStatus === 200){
          this.fetchAdDetails()
          this.adBannerStatus = !this.adBannerStatus
          if(this.adBannerStatus){
            this.toasterData = {
                  status: true,
                  msg: this.$t("your ad have been paused"),
                  timeoutMs: '5000',
                  color: '#000',
                };
          }else{
            this.toasterData = {
                  status: true,
                  msg: this.$t("your ad have been played"),
                  timeoutMs: '5000',
                  color: '#000',
                };

          }
        }

      if (data.message === 'Invalid Token') {
        SessionHelper.refereshJwtToken();
        if (SessionHelper.getCookie('JWT-TOKEN') !== 'null') {
          this.handlePlayPauseAd();
        }
      }
    })
    .catch(()=>{
      this.isDisable = false
    })
   },
  fetchAdDetails() {
    ApiRequest(BASE_API_URL,`/campaign/details/${this.$route.query.AdNo}`, "get").then((data) => {
        if (data.httpStatus === 200) {
          this.adsDetail = data.data;
          this.loading = false;
        } else {
          this.loading = false;
          this.toasterData = {
            status: true,
            msg: data.message,
            timeoutMs: '5000',
            color: '#000',
          };
          if (data.message === 'Invalid Token') {
            SessionHelper.refereshJwtToken();
            if (SessionHelper.getCookie('JWT-TOKEN') !== 'null') {
              this.fetchAdDetails();
            }
          }
        }
      });
    },
    extractDate(timestamp) {
      const month = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      return (
        new Date(timestamp).getDate() +
        ' ' +
        month[new Date(timestamp).getMonth()] +
        ' ' +
        new Date(timestamp).getFullYear()
      );
    },
  },
  mounted() {
    this.fetchAdDetails();
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/ads-detail.scss";
</style>
