var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.adsDetail)?_c('div',{staticClass:"adsdetail-cnt"},[_c('header',{staticClass:"inner-header"},[_c('div',{staticClass:"top-head"},[_c('img',{attrs:{"src":require("../assets/images/backIcon.svg"),"alt":""},on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('span',{staticClass:"edit-btn",on:{"click":function($event){return _vm.$router.push({
                path: '/create-ad-form',
                query: { AdId: _vm.adsDetail.campaignIdentifier },
              })}}},[_vm._v(_vm._s(_vm.$t('Edit detail')))])]),_c('h2',[_vm._v(" "+_vm._s(_vm.adsDetail.name)+" ")])]),_c('section',{staticClass:"mid-cnt"},[_c('div',{staticClass:"ads-status"},[_c('div',{staticClass:"status-col"},[_c('div',{staticClass:"status-box"},[_c('span',{staticClass:"text-val"},[_vm._v(_vm._s(_vm.adsDetail.clickCount))]),_c('p',[_vm._v(_vm._s(_vm.$t('Clicks')))])])]),_c('div',{staticClass:"status-col"},[_c('div',{staticClass:"status-box"},[_c('span',{staticClass:"text-val"},[_vm._v(_vm._s(_vm.adsDetail.viewCount))]),_c('p',[_vm._v(_vm._s(_vm.$t('Viewers')))])])])]),_c('div',{staticClass:"detail-box"},[((_vm.adsDetail.status === 2))?_c('div',{class:_vm.isDisable ? 'adbanner-staus disable-btn' : 'adbanner-staus'},[_c('span',{staticClass:"status-btn play-ad",class:_vm.adBannerStatus ? 'play-ad' : 'pause-ad',on:{"click":_vm.handlePlayPauseAd}},[(_vm.adBannerStatus)?_c('span',[_vm._v(_vm._s(_vm.$t('AdPlay')))]):_c('span',[_vm._v(_vm._s(_vm.$t('AdPause')))])])]):_vm._e(),_c('div',{staticClass:"info-list"},[_c('h4',[_vm._v(_vm._s(_vm.$t('AdName')))]),_c('p',[_vm._v(_vm._s(_vm.adsDetail.name))])]),_c('div',{staticClass:"info-list"},[_c('h4',[_vm._v(_vm._s(_vm.$t('AdDescription')))]),_c('p',[_vm._v(" "+_vm._s(_vm.adsDetail.description)+" ")])]),(
              _vm.adsDetail.campaignAction.callNumber ||
                _vm.adsDetail.campaignAction.whatsAppNumber
            )?_c('div',{staticClass:"info-list"},[_c('h4',[_vm._v(_vm._s(_vm.$t('Phone'))+":")]),_c('p',[_vm._v(" "+_vm._s(_vm.adsDetail.campaignAction.callNumber || _vm.adsDetail.campaignAction.whatsAppNumber)+" ")])]):_vm._e(),_c('div',{staticClass:"info-list"},[_c('h4',[_vm._v(_vm._s(_vm.$t('Status')))]),_c('p',[_vm._v(" "+_vm._s(new Date().getTime() > _vm.adsDetail.endDate ? "Expired" : _vm.adsDetail.status === 1 ? 'Pending' : _vm.adsDetail.status === 2 ? 'Approved' : 'Rejected')+" ")])]),_c('div',{staticClass:"info-list"},[_c('h4',[_vm._v(_vm._s(_vm.$t('Created At')))]),_c('p',[_vm._v(" "+_vm._s(_vm.extractDate(_vm.adsDetail.startDate))+" ")])]),_c('div',{staticClass:"info-list"},[_c('h4',[_vm._v(_vm._s(_vm.$t('Expired on')))]),_c('p',[_vm._v(" "+_vm._s(_vm.extractDate(_vm.adsDetail.endDate))+" ")])]),(_vm.adsDetail.hasOwnProperty('leadData'))?_c('div',[(_vm.adsDetail.leadData.length && _vm.adsDetail.leadData[0].email)?_c('div',{staticClass:"info-list"},[_c('h4',[_vm._v(_vm._s(_vm.$t('Customer Email')))]),_c('p',[_vm._v(" "+_vm._s(_vm.adsDetail.leadData && _vm.adsDetail.leadData[0].email)+" ")])]):_vm._e(),(_vm.adsDetail.leadData.length && _vm.adsDetail.leadData[0].name)?_c('div',{staticClass:"info-list"},[_c('h4',[_vm._v(_vm._s(_vm.$t('Customer Name')))]),_c('p',[_vm._v(" "+_vm._s(_vm.adsDetail.leadData && _vm.adsDetail.leadData[0].name)+" ")])]):_vm._e(),(
                _vm.adsDetail.leadData.length && _vm.adsDetail.leadData[0].phoneNumber
              )?_c('div',{staticClass:"info-list"},[_c('h4',[_vm._v(_vm._s(_vm.$t('Customer Phone')))]),_c('p',[_vm._v(" "+_vm._s(_vm.adsDetail.leadData && _vm.adsDetail.leadData[0].phoneNumber)+" ")])]):_vm._e(),(_vm.adsDetail.leadData.length && _vm.adsDetail.leadData[0].pincode)?_c('div',{staticClass:"info-list"},[_c('h4',[_vm._v(_vm._s(_vm.$t('Customer Pincode')))]),_c('p',[_vm._v(" "+_vm._s(_vm.adsDetail.leadData && _vm.adsDetail.leadData[0].pincode)+" ")])]):_vm._e(),(_vm.adsDetail.leadData.length && _vm.adsDetail.leadData[0].pincode)?_c('div',{staticClass:"info-list"},[_c('h4',[_vm._v(_vm._s(_vm.$t('Customer Pincode')))]),_c('p',[_vm._v(" "+_vm._s(_vm.adsDetail.leadData && _vm.adsDetail.leadData[0].pincode)+" ")])]):_vm._e()]):_vm._e(),(_vm.adsDetail.campaignMedia.length || _vm.adsDetail.leadMedia)?_c('div',{staticClass:"info-list"},[_c('h4',[_vm._v(_vm._s(_vm.$t('AdImage')))]),_c('div',{staticClass:"upload-item"},[(_vm.adsDetail.campaignMedia.length)?_c('img',{attrs:{"src":_vm.adsDetail.campaignMedia && _vm.adsDetail.campaignMedia[0].mediaURL}}):_vm._e(),(_vm.adsDetail.leadMedia)?_c('img',{attrs:{"src":_vm.adsDetail.leadMedia.mediaURL,"alt":""}}):_vm._e()])]):_vm._e()])])]):_vm._e(),(_vm.loading)?_c('loadingIcon'):_vm._e(),_c('Toaster',{attrs:{"dataValue":_vm.toasterData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }