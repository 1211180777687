<template>
  <div class="toaster">
    <div
      class="toaster_slide"
      :class="{ show_toaster: status == true }"
      id="snackbar"
      :style="{ background: color }"
    >
      <span class="close_button" @click="status = false">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19
            12 13.41 17.59 19 19 17.59 13.41 12z"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </span>
      <p>{{ msg }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Toaster",
  props: {
    dataValue: {
      type: Object,
    },
  },
  data() {
    return {
      msg: "",
      color: "#fffff",
      status: false,
    };
  },
  watch: {
    dataValue() {
      if (this.dataValue) {
        this.status = this.dataValue.status;
        this.msg = this.dataValue.msg;
        this.color = this.dataValue.color;
        setTimeout(
          () => {
            this.status = false;
          },
          this.dataValue.timeoutMs ? this.dataValue.timeoutMs : 500000
        );
      }
    },
  },
  mounted() {
    if (this.dataValue) {
      this.status = this.dataValue.status;
      this.msg = this.dataValue.msg;
      this.color = this.dataValue.color;
      setTimeout(
        () => {
          console.log("==chlaa==");
          this.status = false;
        },
        this.dataValue.timeoutMs ? this.dataValue.timeoutMs : 500000
      );
    }
  },
};
</script>
<style scoped>
.toaster {
  width: 100%;
  transition: all ease-in 0.3s;
  position: fixed;
  left: 0px;
  top: 0;
  z-index: 111;
}
#snackbar {
  width: 100%;
  color: #fff;
  text-align: left;
  padding: 12px 40px 12px 16px;
}

.toaster_slide {
  transition: all cubic-bezier(0.55, 0.06, 0.24, 1.4) 0.3s;
  position: fixed;
  top: -100%;
  left: 0px;
  z-index: 11;
}

.toaster_slide.show_toaster {
  top: 0px;
}

#snackbar p {
  text-transform: capitalize;
  font-size: 0.875em;
}

#snackbar .close_button {
  background: #fff;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: inline-block;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}
#snackbar .close_button svg {
  width: 20px;
  height: 20px;
  fill: #ff6600;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
</style>
